import React, { useEffect, useRef, useState } from "react";
import { Box, Slider, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

import ListenerWrapper from "@listener";
import LanguageList from "@commons/LanguageList";
import Loader from "@commons/Loader";
import Button from "@commons/Button";
import { DEFAULT_AUDIO_VOLUME } from "@utils/Constants";
import FeedbackModal from "@commons/Feedback";

const Transcription = ({
  transcription,
  language,
  setLanguage,
  emitData,
  setVolume,
  handleStopListening,
  loading,
  redirectToHome,
  disableVoice,
}) => {
  const [localVolume, setLocalVolume] = useState(DEFAULT_AUDIO_VOLUME);
  const [openFeedback, setOpenFeedback] = useState(false);
  const theme = useTheme();
  const textRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (textRef.current) {
      textRef.current.scrollTop = textRef.current.scrollHeight;
    }
  }, [transcription]);

  const handleVolumeChange = (_, newValue) => {
    const total = 1;
    const translationVolume = newValue / 100;
    const originalVolume = (total - translationVolume).toFixed(1);
    setVolume({
      // original: parseFloat(originalVolume),
      original: 0,
      translation: parseFloat(translationVolume.toFixed(1)),
    });
  };

  const handleLocalVolumeChange = (_, value) => {
    setLocalVolume(value / 100);
  };

  const handleLanguageChange = (event) => {
    emitData(event.target.value);
    setLanguage((prev) => ({ ...prev, output: event.target.value }));
  };

  const handleStop = () => {
    setOpenFeedback(true);
    redirectToHome();
  };

  const handleFeedbackClose = () => {
    setOpenFeedback(false);
    redirectToHome();
  };

  const handleFeedBack = (rating) => {
    handleStopListening(rating);
    handleStop();
  };

  return (
    <ListenerWrapper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          //   justifyContent: "center",
          flexDirection: { xs: "column-reverse", sm: "row" }, // Responsive layout: column for small screens
          height: { xs: "550px", sm: "auto" }, // Responsive layout: column for small screens
          p: 2,
          width: { xs: "100%", sm: "78%" },
        }}
      >
        {/* Left side: Volume, Language, and Voice controls */}
        <Box
          sx={{
            width: { xs: "100%", sm: "45%" }, // Full width on small screens
            padding: "20px",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" }, // Center elements on small screens
          }}
        >
          {!disableVoice && (
            <>
              <Typography
                variant="text"
                sx={{ marginTop: 2, alignSelf: "flex-start" }}
              >
                {t("volume")}
              </Typography>
              <Slider
                value={localVolume * 100}
                onChange={handleLocalVolumeChange}
                onChangeCommitted={handleVolumeChange}
                aria-labelledby="volume-slider"
                sx={{
                  width: "100%",
                  "& .MuiSlider-track": {
                    height: "4px ",
                    borderColor: "#fff ",
                    color: "#d3addf",
                    borderRadius: 0,
                  },
                  "& .MuiSlider-rail": {
                    height: "4px ",
                    borderColor: "#fff ",
                    color: "#FFFFFF ",
                    opacity: "1 ",
                    borderRadius: 0,
                  },
                  "& .MuiSlider-thumb": {
                    color: "#d3addf",
                  },
                }} // Adjust width on small screens
              />
              {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="subtitle">{t("original")}</Typography>
            <Typography variant="subtitle">{t("translated")}</Typography>
            </Box> */}
            </>
          )}
          {/* <Box sx={{ width: "100%", mt: "10px" }}>
            <LanguageList
              onChange={handleLanguageChange}
              selected={language}
              type="dropdown"
              width="100%"
            />
          </Box> */}
          {/* <Button onClick={handleStop} sx={{ width: "100%", mt: "10px" }}>
            {t("stopListening")}
          </Button> */}
        </Box>

        {/* Right side: Text display */}
        <Box
          sx={{
            p: 2,
            borderRadius: "12px",
            backgroundColor: theme.palette.secondary.main,
            marginLeft: { xs: 0, sm: 2 }, // Remove margin for small screens
            marginTop: { xs: 2, sm: 0 }, // Add top margin on small screens
            color: theme.palette.text.primary,
            width: { xs: "100%", sm: "700px" }, // Full width on small screens
            height: { xs: "100%", sm: "500px" }, // Full width on small screens
            overflowY: "scroll",
            scrollbarWidth: "none", // For Firefox
            "&::-webkit-scrollbar": {
              display: "none", // For Chrome, Safari, and Edge
            },
            textAlign: "start",
          }}
          ref={textRef}
        >
          <Typography variant="text">{transcription}</Typography>
        </Box>
        {/* </Box> */}
      </Box>
      <Loader open={loading} text={t("roomText")} />
      <FeedbackModal
        open={openFeedback}
        handleClose={handleFeedbackClose}
        handleFeedBack={handleFeedBack}
      />
    </ListenerWrapper>
  );
};

export default Transcription;
