import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";

import Button from "@commons/Button";
import { useTheme } from "@emotion/react";

const QRCodeDialog = ({ open, url: urlToShare, handleClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.secondary.main,
          padding: "20px",
        },
      }}
    >
      <DialogContent>
        <Box display="flex" justifyContent="center" my={2}>
          <QRCodeSVG value={urlToShare} size={220} marginSize={"2"} />
        </Box>
        <Typography align="center">
          Scan the QR code above to join the listening room
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodeDialog;
