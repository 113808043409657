import "./App.css";
import useInactivityTimer from "./hooks/useInactivityTimer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import { PrivateRoute } from "./utils/Auth";
import { Box, styled } from "@mui/material";

import Login from "@speaker-screens/Login";
import Recorder from "@speaker-screens/Recorder";
import LanguageSelector from "@speaker-screens/LanguageSelector";
import ListenerSelectLanguage from "@listener-screens/LanguageSelector";
import { SetupRedirect } from "@helpers";
import ListeningRoom from "@listener-screens/ListeningRoom";
import { PrivateRoute } from "./utils/Auth";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SharingRoom from "./components/speaker/screens/SharingRoom";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "100vh",
  textAlign: "center",
  backgroundColor: theme.palette.background.default, // Example background color
  color: theme.palette.text.primary, // Example text color
}));

function App() {
  useInactivityTimer(5 * 60 * 60 * 1000); // 5 hours in milliseconds
  const { user } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("i18nLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage); // Set the stored language as the current language
    }
  }, [i18n]);

  return (
    <Container>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/speaker/login" />} />
          <Route path="/speaker/login" element={<Login />} />
          <Route
            path="/speaker/select-language"
            element={
              <PrivateRoute element={<LanguageSelector />} user={user} />
            }
          />
          <Route
            path="/speaker/recorder/:language/:voice/:id"
            element={<PrivateRoute element={<Recorder />} user={user} />}
          />
          <Route
            path="/speaker/share/:language/:id"
            element={<SharingRoom />}
          />
          <Route
            path="/listener/select-language/:language/:id"
            element={<ListenerSelectLanguage />}
          />
          <Route
            path="/listener/setup/:language/:id"
            element={<SetupRedirect />}
          />
          <Route
            path="/listener/room/:languages/:id"
            element={<ListeningRoom />}
          />
          <Route
            path="/listener/room/:languages/:id/:transcriptOnly"
            element={<ListeningRoom />}
          />
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
